import { computed }      from 'mobx';
import RhPrivateApiModel from '../../abstracts/RhPrivateApiModel';

export default class StaffMemberLeaveModel extends RhPrivateApiModel {
	public _filters: ModelFiltersExtended<{ staffMember: id; }> = {};

	static get path(): string {
		return '/staff_member_leaves/{?id}';
	}

	@computed
	public get staffMemberIri(): string {
		return this.get('staffMember.@id', '');
	}

	@computed
	public get staffMemberId(): string {
		return this.get('staffMember.id', '');
	}

	@computed
	public get leaveTypeIri(): string {
		return this.get('leaveType.@id', '');
	}

	@computed
	public get startDate(): string {
		return this.get('startDate', '');
	}

	@computed
	public get endDate(): string {
		return this.get('endDate', '');
	}
}
