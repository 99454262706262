import { MenuOutlined }         from '@ant-design/icons';
import AntTable                 from 'antd/lib/table';
import Tag                      from 'antd/lib/tag';
import { HTML5Backend }         from 'react-dnd-html5-backend';
import { useDrag }              from 'react-dnd';
import { useDrop }              from 'react-dnd';
import { DndProvider }          from 'react-dnd';
import React                    from 'react';
import { ITableProps }          from './PageList';

interface DraggableBodyRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}

const type = 'DraggableBodyRow';

const DragHandle = () => <MenuOutlined style={{ color: '#555', cursor: 'grab' }} />;

const DraggableBodyRow = ({
	children,
	className,
	index,
	moveRow,
	...restProps
}: DraggableBodyRowProps) => {
	const refRow = React.useRef<HTMLTableRowElement | null>(null);
	const refCell = React.useRef<HTMLTableCellElement | null>(null);

	const [{ dropClassName, isOver }, drop] = useDrop({
		accept: type,
		collect: monitor => {
			const { index: dragIndex } = monitor.getItem() || {};
			if (dragIndex === index) {
				return {};
			}
			return {
				dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
				isOver: monitor.isOver(),
			};
		},
		drop: (item: { index: number }) => {
			moveRow(item.index, index);
		},
	});
	const [, drag, preview] = useDrag({
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
		item: { index },
		type,
	});
	drop(refRow);
	drag(refCell);

	React.useEffect(() => {
		preview(refRow.current);
	}, []);

	const isRowSelectionEnabled = Array.isArray(children)
		&& children.findIndex(child => child.props.className === 'ant-table-selection-column') !== -1;
	const filteredChildren = Array.isArray(children)
		? children.filter((c, i) => i !== (isRowSelectionEnabled ? 1 : 0))
		: children;
	const leftChildren = Array.isArray(filteredChildren) && isRowSelectionEnabled
		? filteredChildren.shift()
		: undefined;

	return (
		<tr
			className={`${className}${isOver ? dropClassName : ''}`}
			ref={refRow}
			{...restProps}
		>
			{leftChildren}
			<td className="ant-table-cell ant-table-drag-column" ref={refCell}><DragHandle /></td>
			{filteredChildren}
		</tr>
	);
};

class PageListTable extends React.Component<ITableProps> {

	public refTable = React.createRef<HTMLDivElement>();

	public constructor(props) {
		super(props);

		// Suppression de la propriété defaultSortOrder
		props.columns?.forEach(c => delete c.defaultSortOrder);

		if (props.initialSortName && props.initialSortWay) {
			const column = props.columns?.find(c => c.key === props.initialSortName);

			if (column) {
				column.defaultSortOrder = props.initialSortWay === 'asc' ? 'ascend' : 'descend';
			}
		}
	}

	public render() {
		const { initialSortName, sortable } = this.props;

		if (sortable && initialSortName === 'position') {
			return this.renderSortable();
		}

		return <AntTable {...this.props} />;
	}
	
	public renderSortable() {
		const props = {
			...this.props,
			columns: [
				{
					width: 10,
				},
				{
					dataIndex: 'order',
					render: (v, r, index: number) => <Tag color="blue">{index + 1}</Tag>,
					title: 'Ordre',
					width: 10,
				},
				...(this.props.columns || [])
			]
		};

		const components = {
			body: {
				row:  props.dataSource?.length ? DraggableBodyRow : undefined,
			},
			header: {
				row: props.dataSource?.length ? ({ children, ...rest }) => {
					const filteredColIndex = props.rowSelection !== undefined ? 1 : 0;
					const filteredChildren = Array.isArray(children)
						? children.filter((c, i) => i !== filteredColIndex)
						: children;
					const leftChildren = props.rowSelection !== undefined && Array.isArray(filteredChildren)
						? filteredChildren.shift()
						: undefined;

					return (
						<tr {...rest}>
							{leftChildren}
							<td>&nbsp;</td>
							{filteredChildren}
						</tr>
					);
				} : undefined,
			}
		};

		return (
			<DndProvider backend={HTML5Backend}>
				<AntTable
					{...props}

					components={this.refTable.current ? components : undefined}
					onRow={(_, index) => {
						const attr = {
							index,
							moveRow: props.onDrop,
						};
						return attr as React.HTMLAttributes<never>;
					}}
				
					ref={this.refTable as never}
				/>
			</DndProvider>
		);
	}
}

export default PageListTable;