import doc                  from 'decorators/doc';
import model                from 'decorators/model';
import SalesPrivateApiModel from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('PurchaseOrderTargetStatus')
@model.urnResource('purchase_order_target_status')
@doc.path('/purchase_order_target_statuses/{?id}')
export default class PurchaseOrderTargetStatusModel extends SalesPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'reference': SalesPurchaseOrderTargetStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@doc.string declare label: string;
	@doc.string declare reference: SalesPurchaseOrderTargetStatusReference;
}