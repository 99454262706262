import doc                  from 'decorators/doc';
import model                from 'decorators/model';
import { Blamable }         from 'helpers/traits';
import { Timestampable }    from 'helpers/traits';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

@model.staticLabel('Statut de blocage')
@model.urnResource('block_request_status')
@doc.path('/block_request_statuses/{?id}')
export default class BlockRequestStatusModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'reference': BlockRequestStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'updatedAt': string;
	}> = {};

	public static cacheDuration = 3600;

	@doc.string declare label:string;
	@doc.string declare reference:BlockRequestStatusReference;

	public get color() {
		switch (this.reference) {
			case 'to_validate':
				return 'grey';
			case 'blocked':
				return 'red';
			case 'unblocked':
				return 'orange';
			case 'resolved':
				return 'green';
			case 'canceled':
				return 'purple';
		}
	}
}