import { PagedCollection }      from 'Collections/PagedCollection';
import EventModel               from 'Models/auditer/EventModel';
import CompanyModel             from 'Models/directory/CompanyModel';
import ContactModel             from 'Models/directory/ContactModel';
import EnterpriseModel          from 'Models/directory/EnterpriseModel';
import EnterprisePartitionModel from 'Models/directory/EnterprisePartitionModel';
import FileModel                from 'Models/file/FileModel';
import InterventionModel        from 'Models/intervention/InterventionModel';
import TaskZoneModel            from 'Models/intervention/TaskZoneModel';
import BillableItemModel        from 'Models/invoice/BillableItemModel';
import BillableModel            from 'Models/invoice/BillableModel';
import BillableStatusModel      from 'Models/invoice/BillableStatusModel';
import BillingGroupMetaModel    from 'Models/invoice/BillingGroupMetaModel';
import BillingGroupModel        from 'Models/invoice/BillingGroupModel';
import BillingOwnerModel        from 'Models/invoice/BillingOwnerModel';
import BillingPeriodModel       from 'Models/invoice/BillingPeriodModel';
import ChannelNotificationModel from 'Models/invoice/ChannelNotificationModel';
import ContactTypeModel         from 'Models/invoice/ContactTypeModel';
import InvoiceInvoiceTagModel   from 'Models/invoice/InvoiceInvoiceTagModel';
import InvoiceItemMetaModel     from 'Models/invoice/InvoiceItemMetaModel';
import InvoiceItemModel         from 'Models/invoice/InvoiceItemModel';
import InvoiceMetaModel         from 'Models/invoice/InvoiceMetaModel';
import InvoiceModel             from 'Models/invoice/InvoiceModel';
import InvoiceNotificationModel from 'Models/invoice/InvoiceNotificationModel';
import InvoiceRecoveryModel     from 'Models/invoice/InvoiceRecoveryModel';
import InvoiceTagModel          from 'Models/invoice/InvoiceTagModel';
import InvoiceTypeModel         from 'Models/invoice/InvoiceTypeModel';
import InvoicingContactModel    from 'Models/invoice/InvoicingContactModel';
import InvoicingGroupModel      from 'Models/invoice/InvoicingGroupModel';
import InvoicingOwnerModel      from 'Models/invoice/InvoicingOwnerModel';
import NotificationTypeModel    from 'Models/invoice/NotificationTypeModel';
import BlockRequestModel        from 'Models/sales/BlockRequestModel';
import ContractActorGroupModel  from 'Models/sales/ContractActorGroupModel';
import ContractIterationModel   from 'Models/sales/ContractIterationModel';
import ContractModel            from 'Models/sales/ContractModel';
import QuotationActorGroupModel from 'Models/sales/QuotationActorGroupModel';
import QuotationModel           from 'Models/sales/QuotationModel';
import { computed }             from 'mobx';
import { ApiCollection }        from 'modelx/collections/ApiCollection';
import AbstractModelXStore      from 'stores/AbstractModelXStore';
import { getIdFromUrn }         from 'tools/UrnTools';
import { whenAsync }            from 'tools/modelxTools';

export default class InvoiceDashboardStore extends AbstractModelXStore {

	public billableStatusCollection = new ApiCollection(BillableStatusModel);
	public billingGroup = new BillingGroupModel();
	public billingGroupMetaCollection = new ApiCollection(BillingGroupMetaModel);
	public billingOwner = new BillingOwnerModel();
	public billingPeriod = new BillingPeriodModel();
	public channelNotificationCollection = new ApiCollection(ChannelNotificationModel);
	public collectionInvoiceRecovery = new ApiCollection(InvoiceRecoveryModel);
	public company = new CompanyModel();
	public contract = new ContractModel();
	public contractActorGroupCollection = new ApiCollection(ContractActorGroupModel);
	public contractIteration = new ContractIterationModel();
	public enterprise = new EnterpriseModel();
	public enterprisePartition = new EnterprisePartitionModel();
	public eventCollection = new PagedCollection(EventModel);
	public interventionCollection = new ApiCollection(InterventionModel);
	public invoice = new InvoiceModel();
	public invoiceDocumentOtherFileCollection = new ApiCollection(FileModel);
	public invoiceDocumentPdfFileCollection = new ApiCollection(FileModel);
	public invoiceInvoiceTagCollection = new ApiCollection(InvoiceInvoiceTagModel);
	public invoiceItemCollection = new ApiCollection(InvoiceItemModel);
	public invoiceItemMetaInterventionUrnCollection = new ApiCollection(InvoiceItemMetaModel);
	public invoiceItemMetaLocationUrnCollection = new ApiCollection(InvoiceItemMetaModel);
	public invoiceMetaCollection = new ApiCollection(InvoiceMetaModel);
	public invoiceRefundedCollection = new ApiCollection(InvoiceModel);
	public invoiceTagCollection = new ApiCollection(InvoiceTagModel);
	public invoiceTypeCollection = new ApiCollection(InvoiceTypeModel);
	public invoicingContactCollection = new ApiCollection(InvoicingContactModel);
	public invoicingContactContactCollection = new ApiCollection(ContactModel);
	public invoicingContactTypeCollection = new ApiCollection(ContactTypeModel);
	public invoicingGroup = new InvoicingGroupModel();
	public invoicingOwner = new InvoicingOwnerModel();
	public notificationTypeCollection = new ApiCollection(NotificationTypeModel);
	public pagedCollectionBlockRequest = new PagedCollection(BlockRequestModel);
	public pagedCollectionIssuanceInvoiceNotification = new PagedCollection(InvoiceNotificationModel);
	public pagedCollectionReminderInvoiceNotification = new PagedCollection(InvoiceNotificationModel);
	public quotation = new QuotationModel();
	public quotationActorGroupCollection = new ApiCollection(QuotationActorGroupModel);
	public refundedByBillableCollection = new ApiCollection(BillableModel);
	public refundedByBillableItemCollection = new ApiCollection(BillableItemModel);
	public refundedByInvoiceCollection = new ApiCollection(InvoiceModel);
	public refundsInvoiceCollection = new ApiCollection(InvoiceModel);
	public taskZoneCollection = new ApiCollection(TaskZoneModel);

	public load(invoiceId) {
		this.refundedByBillableCollection.setFilters({
			'billableStatus.reference': ['in_creation', 'created', 'validated', 'to_validate'],
			'refundedInvoices.invoiceRefunded': invoiceId,
		});
		this.refundedByBillableCollection.list().then(() => {
			this.refundedByBillableItemCollection.listByFromCollection(this.refundedByBillableCollection, 'id', 'billable');
		});

		this.refundedByInvoiceCollection.setFilter('refundedInvoices.invoiceRefunded', invoiceId);
		this.refundedByInvoiceCollection.list();

		this.refundsInvoiceCollection.setFilter('refundedByInvoices.refund', invoiceId);
		this.refundsInvoiceCollection.list();

		this.collectionInvoiceRecovery.setFilter('invoice', invoiceId).list();

		this.invoice.setId(invoiceId);
		this.invoice.fetch().then(() => {
			this.billingGroup.setId(this.invoice.getId('billingGroup'));
			this.billingGroup.fetch().then(() => {
				this.invoicingGroup.setId(this.billingGroup.getId('invoicingGroup')).fetch().then(() => {
					this.billingOwner.setId(this.billingGroup.getId('billingOwner')).fetch().then(async () => {
						await whenAsync(() => this.billingGroup.billingOwner.isLoaded);
						await this.company.setId(this.billingGroup.billingOwner.companyId).fetch();

						await Promise.all([
							this.enterprisePartition
								.setId(this.billingOwner.enterprisePartitionId)
								.fetch()
								.then(async () => {
									await this.enterprise.setId(this.enterprisePartition.getId('enterprise')).fetch();
								}),
						]);
					});

					this.invoicingOwner
						.setId(this.invoicingGroup.getId('invoicingOwner'))
						.fetch()
						.then(() => {
							this.invoicingContactCollection
								.setFilter('enterprisePartitionUrn', this.invoicingGroup.enterprisePartitionUrn)
								.list()
								.then(async () => {
									await this.invoicingContactContactCollection
										.listByFromCollection(this.invoicingContactCollection, 'contactId', 'id');
								});
						});
				});
			});

			this.billingGroupMetaCollection.setFilter('billingGroup', this.invoice.getId('billingGroup'));
			this.billingGroupMetaCollection.list().then(() => {
				const billingGroupMetaContractIterationUrn = this.billingGroupMetaCollection.find(billingGroupMeta => billingGroupMeta.reference === 'contract_iteration_urn');
				const billingGroupMetaQuotationUrn = this.billingGroupMetaCollection.find(billingGroupMeta => billingGroupMeta.reference === 'quotation_urn');
				if (billingGroupMetaContractIterationUrn) {
					const contractIterationId = getIdFromUrn(billingGroupMetaContractIterationUrn.value.toString());
					this.contractIteration.setId(contractIterationId);
					this.contractIteration.fetch().then(() => {
						this.contract.setId(this.contractIteration.contractId);
						this.contract.fetch();
					});
				} else if (billingGroupMetaQuotationUrn) {
					const quotationId = getIdFromUrn(billingGroupMetaQuotationUrn.value.toString());
					this.quotation.setId(quotationId);
					this.quotation.fetch();
				}
			});

			this.billingPeriod.setId(this.invoice.getId('billingPeriod'));
			this.billingPeriod.fetch();

			this.eventCollection
				.setFilters({
					'subject.metadata.recipient_type': 'TO',
					'subject.urn[urn]': '$:invoice:mail:send_invoice',
				})
				.listBy([this.invoice.urn], 'subject.metadata.invoice_urn[eq]')
				.catch(() => null);

			this.invoiceDocumentOtherFileCollection.setFilters({
				'typeSource.type.reference': 'invoice_other',
			});
			this.invoiceDocumentOtherFileCollection.listBy([this.invoice.urn], 'typeSource.source.entityUrn');

			this.invoiceDocumentPdfFileCollection.setFilters({
				'typeSource.type.reference': 'invoice_pdf',
			});
			this.invoiceDocumentPdfFileCollection.listBy([this.invoice.urn], 'typeSource.source.entityUrn');
		});

		this.invoiceInvoiceTagCollection.listBy([invoiceId], 'invoice');

		this.invoiceItemCollection.listBy([invoiceId], 'invoiceItemGroup.invoice');

		this.invoiceItemMetaLocationUrnCollection.setFilters({
			'reference': 'location_urn',
		});
		this.invoiceItemMetaLocationUrnCollection.listBy([invoiceId], 'invoiceItem.invoiceItemGroup.invoice').then(() => {
			const taskZoneIds = this.invoiceItemMetaLocationUrnCollection
				.map(iim => getIdFromUrn(iim.value.toString()));

			return this.taskZoneCollection.listBy(taskZoneIds);
		});

		this.invoiceItemMetaInterventionUrnCollection.setFilters({
			'reference': 'intervention_urn',
		});
		this.invoiceItemMetaInterventionUrnCollection.listBy([invoiceId], 'invoiceItem.invoiceItemGroup.invoice').then(() => {
			return this.interventionCollection.listByFromCollection(this.invoiceItemMetaInterventionUrnCollection, 'interventionId', 'id');
		});

		this.invoiceMetaCollection.listBy(invoiceId, 'invoice');

		this.invoiceRefundedCollection.listBy([invoiceId], 'refundedInvoices.invoiceRefunded');

		this.loadNotManageableCollection();
	}

	public loadNotManageableCollection() {
		this.billableStatusCollection.list({ cache: 3600 });
		this.channelNotificationCollection.list({ cache: 3600 });
		this.contractActorGroupCollection.list({ cache: 3600 });
		this.invoiceTagCollection.list({ cache: 3600 });
		this.invoiceTypeCollection.list({ cache: 3600 });
		this.invoicingContactTypeCollection.list({ cache: 3600 });
		this.notificationTypeCollection.list({ cache: 3600 });
		this.quotationActorGroupCollection.list({ cache: 3600 });
	}

	@computed
	public get invoiceRecovery() {
		return this.collectionInvoiceRecovery.first() || new InvoiceRecoveryModel();
	}
}
