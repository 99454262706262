import model                from 'decorators/model';
import moonSvg              from 'icons/moon.svg';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

@model.staticLabel('Tag')
@model.urnResource('tag')
export default class TagModel extends SalesPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'tag.tagReference.reference': TagSalesReference,
		'targetUrn': string,
		'tagReference': string,
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	static get path(): string {
		return '/stonecode_tag_taggable_tags/{?id}';
	}

	public get targetUrn(): Urn {
		return this.get('targetUrn');
	}

	public get tagReference(): TagSalesReference {
		return this.get('tagReference');
	}

	public get tagValue(): TagSalesValue {
		return this.get('tagValue');
	}

	public get tagReferenceLabel(): string {
		switch (this.tagReference) {
			case 'schedule_option':
				return 'Option de planification';
			case 'commentable_option':
				return 'Option de commentaire';
			case 'pre_selected':
				return 'Pré-sélectionné';
			case 'contract_iteration_duplication_origin':
			case 'quotation_duplication_origin': 
				return 'Dupliqué à partir de';
		}
	}

	public get tagValueLabel(): string {
		switch (this.tagValue) {
			case 'required':
				return 'Commentaire requis';
			case 'optional':
				return 'Commentaire optionnel';
			case 'multi_day':
				return 'Intervention de nuit';
			default:
				return this.tagReferenceLabel;
		}
	}

	public getTagValueIcon() {
		switch (this.tagValue) {
			case 'multi_day':
				return moonSvg;
		}
	}
}
