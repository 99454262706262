import PurchaseOrderModel               from 'Models/sales/PurchaseOrderModel';
import doc                              from 'decorators/doc';
import model                            from 'decorators/model';
import { Timestampable }                from 'helpers/traits';
import { Blamable }                     from 'helpers/traits';
import SalesPrivateApiModel             from 'modelx/models/abstracts/SalesPrivateApiModel';
import ContractIterationModel           from 'modelx/models/private/sales/ContractIterationModel';
import FrameworkAgreementIterationModel from 'modelx/models/private/sales/FrameworkAgreementIterationModel';
import QuotationModel                   from 'modelx/models/private/sales/QuotationModel';
import PurchaseOrderTargetStatusModel   from './PurchaseOrderTargetStatusModel';

@model.staticLabel('Bon de commande')
@model.urnResource('purchase_order_target')
@doc.path('/purchase_order_targets/{?id}')
export default class PurchaseOrderTargetModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'purchaseOrder': id;
		'purchaseOrderStatus': id;
		'purchaseOrderStatus.reference': SalesPurchaseOrderTargetStatusReference;
		'targetUrn': Urn;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@model.resolvable(PurchaseOrderModel, { attributeName: 'purchaseOrder' })
	declare purchaseOrder: PurchaseOrderModel;

	@model.resolvableUrn({ attributeName: 'targetUrn' })
	declare target: QuotationModel | ContractIterationModel | FrameworkAgreementIterationModel;

	@doc.model(PurchaseOrderTargetStatusModel) declare purchaseOrderTargetStatus: PurchaseOrderTargetStatusModel;
}
