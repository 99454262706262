import { resolvable }                  from '@mathquis/modelx-resolvables';
import InterventionStatusModel         from 'Models/intervention/InterventionStatusModel';
import OperationCategoryModel          from 'Models/intervention/OperationCategoryModel';
import OwnerSubPartitionModel          from 'Models/intervention/OwnerSubPartitionModel';
import { ResourceTypeReference }       from 'Models/intervention/ResourceTypeModel';
import { TaskLevelReference }          from 'Models/intervention/TaskLevelModel';
import { TaskMetaReference }           from 'Models/intervention/TaskMetaModel';
import { TaskTagReference }            from 'Models/intervention/TaskTagModel';
import TaskZoneModel                   from 'Models/intervention/TaskZoneModel';
import PartitionModel                  from 'Models/partition/PartitionModel';
import doc                             from 'decorators/doc';
import model                           from 'decorators/model';
import { Blamable }                    from 'helpers/traits';
import { Timestampable }               from 'helpers/traits';
import { computed }                    from 'mobx';
import moment                          from 'moment';
import { durationFormatted }           from 'tools/dateTools';
import InterventionPrivateApiModel     from '../../abstracts/InterventionPrivateApiModel';

@model.staticLabel(`Intervention`, false)
@model.urnResource('intervention')
@doc.path('/interventions/{?id}')
export default class InterventionModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/interventions/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'anomaliesFlags.flag[between]': string;
		'anomaliesFlags.flag[gt]': string;
		'anomaliesFlags.flag[gte]': string;
		'anomaliesFlags.flag[lt]': string;
		'anomaliesFlags.flag[lte]': string;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'exists[interventionAcks]': string;
		'exists[interventionOperations.taskOperation.mainTaskOperationExtras]': string;
		'interventionGroupInterventions.interventionGroup': id;
		'interventionOperations': id;
		'interventionOperations.taskOperation': id;
		'interventionOperations.taskOperation.operation': id;
		'interventionOperations.taskOperation.operation.operationCategory': id;
		'interventionOperations.taskOperation.task': id;
		'interventionOperations.taskOperation.task.taskLevel': id;
		'interventionOperations.taskOperation.task.taskLevel.reference': TaskLevelReference;
		'interventionOperations.taskOperation.task.taskMetas.reference': TaskMetaReference;
		'interventionOperations.taskOperation.task.taskMetas.value': id;
		'interventionOperations.taskOperation.task.taskTaskTags': id;
		'interventionOperations.taskOperation.task.taskTaskTags.taskTag': id;
		'interventionOperations.taskOperation.task.taskTaskTags.taskTag.reference': TaskTagReference;
		'interventionResources': id;
		'interventionResources.resource': id;
		'interventionResources.resource.ownerResource': id;
		'interventionResources.resource.ownerResource.entityUrn': string;
		'interventionResources.resource.resourceType': id;
		'interventionResources.resource.resourceType.reference': ResourceTypeReference;
		'interventionStatus': id;
		'interventionStatus.reference': InterventionStatusReference;
		'notified': string;
		'ownerSubPartition': id;
		'ownerSubPartition.partitionUrn': string;
		'ownerSubPartition.subPartitionUrn': string;
		'scheduleEndDate[after]': string | Date;
		'scheduleEndDate[before]': string | Date;
		'scheduleEndDate[strictly_after]': string | Date;
		'scheduleEndDate[strictly_before]': string | Date;
		'scheduleStartDate[after]': string | Date;
		'scheduleStartDate[before]': string | Date;
		'scheduleStartDate[strictly_after]': string | Date;
		'scheduleStartDate[strictly_before]': string | Date;
		'taskZone': id;
		'taskZone.owner': id;
		'taskZone.owner.clientUrn': string;
		'taskZone.owner.enterprisePartitionUrn': string;
		'taskZone.owner.enterpriseUrn': string;
		'taskZone.owner.ownerSubPartition': id;
		'taskZone.owner.ownerSubPartition.partitionUrn': string;
		'taskZone.owner.ownerSubPartition.subPartitionUrn': string;
		'taskZone.zipCode': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
		'interventionOperations.taskOperation.task.tags[$:registry:service:sales][contract_iteration_item_urn]': string;
		'interventionOperations.taskOperation.task.tags[$:registry:service:sales][quotation_item_urn]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'interventionStatus.label': string;
		'scheduleEndDate': string;
		'scheduleStartDate': string;
		'updatedAt': string;
	}> = {};

	public get note(): string {
		return this.get('note', '');
	}

	public get noteForClient(): string {
		return this.get('noteForClient', '');
	}

	public get notified(): boolean {
		return this.get('notified', false);
	}

	public get number(): number {
		return this.get('number', 0);
	}

	public get observations(): string {
		return this.get('observations', '');
	}

	@computed
	public get scheduleEndDate(): Moment {
		return moment(this.get('scheduleEndDate', moment()));
	}

	@computed
	public get scheduleStartDate(): Moment {
		return moment(this.get('scheduleStartDate', moment()));
	}

	public get value(): number {
		return this.get('value', 0);
	}

	@computed
	public get displayDate(): string {
		const startDate = moment(this.scheduleStartDate);
		const endDate = moment(this.scheduleEndDate);

		return `${startDate.format('L')} de ${startDate.format('LT')} à ${endDate.format('LT')}`;
	}

	@computed
	public get title() {
		return `${this.displayDate} (${this.number})`;
	}

	@computed
	public get durationFormatted(): string {
		return durationFormatted(this.scheduleStartDate, this.scheduleEndDate, [0, 5]);
	}

	@computed
	public get statusId(): id {
		return this.get('interventionStatus.id', 0);
	}

	@computed
	public get taskZoneId(): id {
		return this.get('taskZone.id', 0);
	}

	@computed
	public get interventionStatusId(): id {
		return this.get('interventionStatus.id');
	}

	public get isMultiDay() {
		return !this.scheduleStartDate.isSame(this.scheduleEndDate, 'day');
	}

	public get needAck(): boolean {
		return this.get('needAck', true);
	}

	@resolvable(InterventionStatusModel, { attributeName: 'interventionStatus', cache: true })
	declare interventionStatus: InterventionStatusModel;

	@resolvable(OperationCategoryModel, { attributeName: 'operationCategory' })
	declare operationCategory: OperationCategoryModel;

	@resolvable(OwnerSubPartitionModel, { attributeName: 'ownerSubPartition' })
	declare ownerSubPartition: OwnerSubPartitionModel;

	@resolvable(PartitionModel, { attributeName: 'partitionUrn', cache: true })
	declare partition: PartitionModel;

	@resolvable(TaskZoneModel, { attributeName: 'taskZone' })
	declare taskZone: TaskZoneModel;

	@model.nestedResolvable()
	public get subPartition() {
		return this.ownerSubPartition.subPartition;
	}

	@computed
	public get fiNumber() {
		return this.number.toString().padStart(6, '0');
	}
}
