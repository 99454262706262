import { ApiCollection }                     from 'Collections/ApiCollection';
import { PagedCollection }                   from 'Collections/PagedCollection';
import EventModel                            from 'Models/auditer/EventModel';
import ClientModel                           from 'Models/directory/ClientModel';
import CompanyModel                          from 'Models/directory/CompanyModel';
import ContactModel                          from 'Models/directory/ContactModel';
import FileModel                             from 'Models/file/FileModel';
import SourceModel                           from 'Models/file/SourceModel';
import TypeSourceModel                       from 'Models/file/TypeSourceModel';
import AnomaliesFlagModel                    from 'Models/intervention/AnomaliesFlagModel';
import CollectableModel                      from 'Models/intervention/CollectableModel';
import CollectableTypeModel                  from 'Models/intervention/CollectableTypeModel';
import ConstraintCheckerBatchModel           from 'Models/intervention/ConstraintCheckerBatchModel';
import ConstraintCheckerModel                from 'Models/intervention/ConstraintCheckerModel';
import ConstraintGroupModel                  from 'Models/intervention/ConstraintGroupModel';
import ConstraintGroupPropertyModel          from 'Models/intervention/ConstraintGroupPropertyModel';
import ContactTypeModel                      from 'Models/intervention/ContactTypeModel';
import InterventionAckModel                  from 'Models/intervention/InterventionAckModel';
import InterventionGroupInterventionModel    from 'Models/intervention/InterventionGroupInterventionModel';
import InterventionGroupModel                from 'Models/intervention/InterventionGroupModel';
import InterventionModel                     from 'Models/intervention/InterventionModel';
import InterventionNoteModel                 from 'Models/intervention/InterventionNoteModel';
import InterventionOperationCollectableModel from 'Models/intervention/InterventionOperationCollectableModel';
import InterventionOperationModel            from 'Models/intervention/InterventionOperationModel';
import InterventionOperationStatusModel      from 'Models/intervention/InterventionOperationStatusModel';
import InterventionResourceModel             from 'Models/intervention/InterventionResourceModel';
import InterventionScheduleModel             from 'Models/intervention/InterventionScheduleModel';
import InterventionStatusHistoryModel        from 'Models/intervention/InterventionStatusHistoryModel';
import InterventionStatusModel               from 'Models/intervention/InterventionStatusModel';
import OperationCategoryModel                from 'Models/intervention/OperationCategoryModel';
import OwnerModel                            from 'Models/intervention/OwnerModel';
import PropertyModel                         from 'Models/intervention/PropertyModel';
import PropertyTypeModel                     from 'Models/intervention/PropertyTypeModel';
import ReportTypeModel                       from 'Models/intervention/ReportTypeModel';
import ResourceTypeModel                     from 'Models/intervention/ResourceTypeModel';
import TagModel                              from 'Models/intervention/TagModel';
import TaskModel                             from 'Models/intervention/TaskModel';
import TaskOperationExtraModel               from 'Models/intervention/TaskOperationExtraModel';
import TaskOperationModel                    from 'Models/intervention/TaskOperationModel';
import TaskOperationStatusModel              from 'Models/intervention/TaskOperationStatusModel';
import TaskScheduleConstraintModel           from 'Models/intervention/TaskScheduleConstraintModel';
import TaskZoneModel                         from 'Models/intervention/TaskZoneModel';
import ZoneContactModel                      from 'Models/intervention/ZoneContactModel';
import BillableItemMetaModel                 from 'Models/invoice/BillableItemMetaModel';
import BillableItemModel                     from 'Models/invoice/BillableItemModel';
import BillableModel                         from 'Models/invoice/BillableModel';
import InvoiceItemMetaModel                  from 'Models/invoice/InvoiceItemMetaModel';
import InvoiceModel                          from 'Models/invoice/InvoiceModel';
import InvoiceStatusModel                    from 'Models/invoice/InvoiceStatusModel';
import StaffMemberModel                      from 'Models/rh/StaffMemberModel';
import VehicleModel                          from 'Models/vehicle/VehicleModel';
import { computed }                          from 'mobx';
import { getIdFromUrn }                      from 'tools/UrnTools';
import { whenAsync }                         from 'tools/modelxTools';
import AbstractModelXStore                   from './AbstractModelXStore';
import { appStore }                          from './index';

export default class InterventionDashboardStore extends AbstractModelXStore {

	public anomaliesFlagCollection = new ApiCollection(AnomaliesFlagModel);
	public billableCollection = new ApiCollection(BillableModel);
	public billableItemCollection = new ApiCollection(BillableItemModel);
	public billableItemMetaCollection = new ApiCollection(BillableItemMetaModel);
	public checker = new ConstraintCheckerModel();
	public client = new ClientModel();
	public collectableCollection = new ApiCollection(CollectableModel);
	public collectableTypeCollection = new ApiCollection(CollectableTypeModel);
	public collectionInterventionNote = new ApiCollection(InterventionNoteModel);
	public collectionInterventionResource = new ApiCollection(InterventionResourceModel);
	public company = new CompanyModel();
	public constraintGroupCollection = new ApiCollection(ConstraintGroupModel);
	public constraintGroupPropertyCollection = new ApiCollection(ConstraintGroupPropertyModel);
	public contactCollection = new ApiCollection(ContactModel);
	public contactTypeCollection = new ApiCollection(ContactTypeModel);
	public eventCollection = new PagedCollection(EventModel);
	public intervention = new InterventionModel();
	public interventionAckCollection = new ApiCollection(InterventionAckModel);
	public interventionAckSignatureFileCollection = new ApiCollection(FileModel);
	public interventionGroupInterventionCollection = new ApiCollection(InterventionGroupInterventionModel);
	public interventionOperationCollectableCollection = new ApiCollection(InterventionOperationCollectableModel);
	public interventionOperationCollection = new ApiCollection(InterventionOperationModel);
	public interventionOperationPhotoFileCollection = new ApiCollection(FileModel);
	public interventionOperationPhotoSourceCollection = new ApiCollection(SourceModel);
	public interventionOperationPhotoTypeSourceCollection = new ApiCollection(TypeSourceModel);
	public interventionOperationStatusCollection = new ApiCollection(InterventionOperationStatusModel);
	public interventionScheduleCollection = new PagedCollection(InterventionScheduleModel);
	public interventionStatusCollection = new ApiCollection(InterventionStatusModel);
	public interventionStatusHistoryCollection = new ApiCollection(InterventionStatusHistoryModel);
	public invoiceCollection = new ApiCollection(InvoiceModel);
	public invoiceItemMetaCollection = new PagedCollection(InvoiceItemMetaModel);
	public invoiceStatusCollection = new ApiCollection(InvoiceStatusModel);
	public owner = new OwnerModel();
	public propertyCollection = new ApiCollection(PropertyModel);
	public propertyTypeCollection = new ApiCollection(PropertyTypeModel);
	public reportTypeCollection = new ApiCollection(ReportTypeModel);
	public resourceTypeCollection = new ApiCollection(ResourceTypeModel);
	public tagReschedulingFromAsTargetCollection = new ApiCollection(TagModel);
	public tagReschedulingFromAsValueCollection = new ApiCollection(TagModel);
	public tagReschedulingFromInterventionCollection= new ApiCollection(InterventionModel);
	public tagReschedulingFromInterventionGroupCollection= new ApiCollection(InterventionGroupModel);
	public taskOperationExtraCollection = new ApiCollection(TaskOperationExtraModel);
	public taskOperationExtraMainCollection = new ApiCollection(TaskOperationExtraModel);
	public taskOperationExtraPagedCollection = new PagedCollection(TaskOperationExtraModel);
	public taskOperationStatusCollection = new ApiCollection(TaskOperationStatusModel);
	public taskOperationTagCollection = new ApiCollection(TagModel);
	public taskScheduleConstraintCollection = new ApiCollection(TaskScheduleConstraintModel);
	public taskZone = new TaskZoneModel();
	public zoneContactCollection = new ApiCollection(ZoneContactModel);

	public async fetchBillableItemsMeta(interventionUrn: string) {
		await this.billableItemMetaCollection
			.setFilters({
				'reference': 'intervention_urn',
				'value': interventionUrn,
			})
			.list();

		await this.billableItemCollection.listByFromCollection(
			this.billableItemMetaCollection,
			'billableItemId',
		);

		await this.billableCollection.listByFromCollection(
			this.billableItemCollection,
			'billableId',
		);
	}

	public async fetchInvoiceItemsMeta(interventionUrn: string) {
		await this.invoiceItemMetaCollection
			.setFilters({
				'reference': 'intervention_urn',
				'value': interventionUrn,
			})
			.list();

		await this.invoiceCollection.listByFromCollection(
			this.invoiceItemMetaCollection,
			'id',
			'invoiceItemGroups.invoiceItems.invoiceItemMetas',
		);
	}

	public async fetchOperations(interventionId: id = this.intervention.id): Promise<void> {
		await this.interventionOperationCollection.setFilters({ intervention: interventionId }).list();

		await Promise.all([
			this.interventionOperationCollection.whenIsLoaded(m => m.taskOperation.operation.operationCategory),
			this.interventionOperationCollection.whenIsLoaded(m => m.taskOperation.task).then(() => {
				const sourceUrns = this.interventionOperationCollection
					.map(io => io.taskOperation.task.getUrn('source'))
					.filter(sourceUrn => !!sourceUrn);

				if (sourceUrns.length) {
					return this.interventionOperationCollection.whenIsLoaded(io => io.taskOperation.task.source);
				}
			}),

			this.fetchTaskOperationExtras(),

			this.interventionOperationCollectableCollection
				.listBy(this.interventionOperationCollection.ids, 'interventionOperation'),

			this.interventionOperationPhotoFileCollection
				.setFilters({
					'typeSource.source.entityUrn': this.interventionOperationCollection.urns,
					'typeSource.type.reference': 'intervention_operation_photo',
				})
				.list(),

			this.interventionOperationPhotoSourceCollection
				.setFilters({
					'entityUrn': this.interventionOperationCollection.urns,
				})
				.list(),

			this.interventionOperationPhotoTypeSourceCollection
				.setFilters({
					'source.entityUrn': this.interventionOperationCollection.urns,
				})
				.list(),

			this._fetchResources(interventionId),

			this._fetchConstraints(),
		]);
	}

	public async fetchReschedulingFromEntities(interventionUrn: Urn) {
		await Promise.all([
			this.tagReschedulingFromAsTargetCollection
				.setFilters({
					'tag.tagReference.reference': 'rescheduling_from',
					'targetUrn': interventionUrn,
				}).list(),
			this.tagReschedulingFromAsValueCollection
				.setFilters({
					'tag.tagReference.reference': 'rescheduling_from',
					'tag.tagValue.value': interventionUrn,
				}).list(),
		]);

		const ids: { 'intervention': string[], 'interventionGroup': string[] } = {
			'intervention': [], 'interventionGroup': [],
		};
		this.tagReschedulingFromAsTargetCollection.forEach(tag => {
			const entityUrn = tag.tagValue;
			const key = entityUrn.includes('intervention_group')
				? 'interventionGroup'
				: 'intervention';
			ids[key].push(getIdFromUrn(entityUrn));
		});
		this.tagReschedulingFromAsValueCollection.forEach(tag => {
			const entityUrn = tag.targetUrn;
			const key = entityUrn.includes('intervention_group')
				? 'interventionGroup'
				: 'intervention';
			ids[key].push(getIdFromUrn(entityUrn));
		});

		if (ids['intervention'].length) {
			await this.tagReschedulingFromInterventionCollection.listBy(ids['intervention'], 'id');
		}
		if (ids['interventionGroup'].length) {
			await this.tagReschedulingFromInterventionGroupCollection.listBy(ids['interventionGroup'], 'id');
		}
	}

	public async fetchStatusHistory(interventionId: id = this.intervention.id): Promise<void> {
		await this.interventionStatusHistoryCollection
			.setSort('id', false)
			.setFilters({ intervention: interventionId })
			.list();
	}

	public fetchTaskOperationExtras() {
		const taskOperationIds = this.interventionOperationCollection.map(io => io.getId('taskOperation'));

		return Promise.all([
			this.taskOperationExtraCollection.setRequiredFilter('extraTaskOperation', taskOperationIds).list(),
			this.taskOperationExtraMainCollection.setRequiredFilter('mainTaskOperation', taskOperationIds).list(),
		]);
	}

	public getInterventionOperationPhotoFiles = (interventionOperationUrn) => {
		const interventionOperationPhotoSources = this.interventionOperationPhotoSourceCollection
			.filter(source => source.entityUrn === interventionOperationUrn);

		const interventionOperationPhotoTypeSources = this.interventionOperationPhotoTypeSourceCollection
			.filter(typeSource => !!interventionOperationPhotoSources.find(source => source.id === typeSource.sourceId));

		return this.interventionOperationPhotoFileCollection
			.filter(file => !!interventionOperationPhotoTypeSources.find(typeSource => typeSource.id === file.typeSourceId));
	};

	public getTaskOperationLinkedRetour(taskOperation: TaskOperationModel) {
		return this.taskOperationExtraMainCollection.find(to => to.getId('mainTaskOperation') === taskOperation.id);
	}

	public getTaskOperationRetour(taskOperation: TaskOperationModel) {
		return this.taskOperationExtraCollection.find(to => to.getId('extraTaskOperation') === taskOperation.id);
	}

	public async initAsync(interventionId: id): Promise<void> {
		this.setIsLoading(true);

		this.clear();

		await Promise.all([
			this._fetchIntervention(interventionId).then(async () => {
				const interventionUrn = this.intervention.urn;

				await Promise.all([
					this.fetchBillableItemsMeta(interventionUrn),
					this.fetchInvoiceItemsMeta(interventionUrn),
					this.fetchOperations(interventionId).then(async () => {
						await this._fetchContacts();
						await this._fetchTaskOperationTags();
					}),
					this.fetchReschedulingFromEntities(interventionUrn),

					this.interventionScheduleCollection
						.setFilter('intervention', interventionId)
						.setSort('createdAt')
						.list(),
				]);
			}),

			this.collectableTypeCollection.list({ cache: 3600 }),
			this.collectableCollection.setSort('label').list({ cache: 3600 }),
			this.reportTypeCollection.list({ cache: 3600 }),
			this.taskOperationStatusCollection.list({ cache: 3600 }),
			this.interventionOperationStatusCollection.list({ cache: 3600 }),
			this.interventionStatusCollection.setSort('label').list({ cache: 3600 }),
			this.resourceTypeCollection.list({ cache: 3600 }),
			this.contactTypeCollection.setSort('position').list({ cache: 3600 }),
			this.invoiceStatusCollection.list({ cache: 3600 }),
			this.collectionInterventionNote.listBy([interventionId], 'intervention'),
			this.interventionGroupInterventionCollection.listBy([interventionId], 'intervention'),
		]);

		await this.interventionGroupInterventionCollection
			.whenIsLoaded(igi => igi.interventionGroup.interventionGroupStatus);

		const constraintCheckers = [{
			excludedIntervention: this.intervention.urn || null,
			resourceUrns: this.collectionInterventionResource.map(v => v.resourceUrn),
			scheduleEndDate: this.intervention.scheduleEndDate,
			scheduleStartDate: this.intervention.scheduleStartDate,
			taskOperations: this.interventionOperationCollection.map(model => model.getUrn('taskOperation')),
		}];
		const constraintCheckerBatch = new ConstraintCheckerBatchModel();
		await constraintCheckerBatch.patch({ constraintCheckers: constraintCheckers });

		this.checker = constraintCheckerBatch.constraintCheckers[0] || new ConstraintCheckerModel();

		this.setIsLoading(false);
	}

	@computed
	public get operationCategory(): OperationCategoryModel {
		return appStore.operationCategoryCollection
			.find(oc => oc.id === this.operationCategoryId) || new OperationCategoryModel();
	}

	@computed
	public get operationCategoryId(): id {
		return this.interventionOperationCollection.first()?.getId('operationCategory') || 0;
	}

	@computed
	public get vehicles() {
		return this.collectionInterventionResource
			.filter(ir => ir.resource.resourceType.reference === 'vehicle')
			.map(ir => ir.resource.ownerResource.entity as VehicleModel);
	}

	@computed
	public get technicians() {
		return this.collectionInterventionResource
			.filter(ir => ir.resource.resourceType.reference === 'technician')
			.map(ir => ir.resource.ownerResource.entity as StaffMemberModel);
	}

	@computed
	public get interventionStatus(): InterventionStatusModel | null {
		return this.interventionStatusCollection.getById(this.intervention.statusId);
	}

	@computed
	public get interventionAck(): InterventionAckModel | null {
		return this.interventionAckCollection.first();
	}

	@computed
	public get interventionAckSignature(): FileModel | null {
		return this.interventionAckSignatureFileCollection.first();
	}

	@computed
	public get internalTechnicianInterventionNote() {
		return this.collectionInterventionNote
			.find(m => m.interventionNoteType.reference === 'internal_technician_comment');
	}

	@computed
	public get issueReportingInterventionNote() {
		return this.collectionInterventionNote.find(m => m.interventionNoteType.reference === 'issue_reporting');
	}

	private async _fetchConstraints() {
		await Promise.all([this._fetchConstraintsResource(), this._fetchConstraintsSchedule()]);
	}

	private async _fetchConstraintsResource() {
		if (this.interventionOperationCollection.length) {
			await this.interventionOperationCollection.whenIsLoaded(io => io.taskOperation);

			const operationIds = this.interventionOperationCollection.distinctIds('operation');

			await this.constraintGroupCollection.setFilters({ operation: operationIds }).list();

			const constraintGroupIds = this.constraintGroupCollection.distinctDefinedKey('id');

			const promises: Promise<unknown>[] = [];

			if (constraintGroupIds.length) {
				promises.push(
					this.constraintGroupPropertyCollection
						.setFilters({ constraintGroup: constraintGroupIds })
						.list()
						.then(async () => {
							await this.propertyCollection
								.listBy(this.constraintGroupPropertyCollection.map(m => m.getId('property')));

							await this.propertyTypeCollection
								.listBy(this.propertyCollection.map(m => m.getId('propertyType')));
						}),
				);

				await Promise.all(promises);
			}
		}
	}

	private async _fetchConstraintsSchedule() {
		if (this.interventionOperationCollection.length) {
			const taskOperationIds = this.interventionOperationCollection.distinctIds('taskOperation');

			await this.taskScheduleConstraintCollection.listBy(taskOperationIds, 'task.taskOperations');
		}
	}

	private async _fetchContacts(): Promise<void> {
		if (this.taskZone.id) {
			await this.zoneContactCollection
				.setFilters({
					operationCategory: this.operationCategoryId,
					taskZone: this.taskZone.id,
				})
				.list();

			const contactIds = this.zoneContactCollection.distinctDefinedKey('contactId');

			await this.contactCollection.setSort('globalContact.firstName').listBy(contactIds);
		}
	}

	private async _fetchIntervention(interventionId: id): Promise<void> {
		await this.intervention.set({ id: interventionId }).fetch();

		const promises: Promise<unknown>[] = [
			this.anomaliesFlagCollection
				.setFilter('intervention', interventionId)
				.list(),

			this.fetchStatusHistory(interventionId),

			this.eventCollection
				.setFilters({
					'subject.metadata.intervention_urn': this.intervention.urn,
					'subject.metadata.recipient_type': 'TO',
					// 'subject.urn[identifier]': INTERVENTION_EVENT_IDENTIFIERS,
				})
				.list()
				.catch(() => null),

			this.interventionAckCollection.setFilters({ intervention: interventionId }).list().then(() => {
				if (this.interventionAck) {
					return this.interventionAckSignatureFileCollection
						.setFilters({
							'typeSource.source.entityUrn': this.interventionAck.urn,
							'typeSource.type.reference': 'intervention_ack_signature',
						})
						.list();
				}
			}),
		];

		if (this.intervention.taskZoneId) {
			promises.push(
				this.taskZone.set({ id: this.intervention.taskZoneId }).fetch().then(async () => {
					await this.owner.set({ id: this.taskZone.getId('owner') }).fetch();

					await this.client.set({ id: this.owner.clientId }).fetch();
					await whenAsync(() => this.client.clientPartition.isLoaded);
					await this.company.set({ id: this.client.clientPartition.getId('company') }).fetch();
				}),
			);
		}

		await Promise.all(promises);
	}

	private async _fetchResources(interventionId: id) {
		await this.collectionInterventionResource.listBy([interventionId], 'intervention');
	}

	private async _fetchTaskOperationTags(): Promise<void> {
		await this.interventionOperationCollection.whenIsLoaded(io => io.taskOperation);

		const taskOperationUrns = this.interventionOperationCollection.map(
			(io) => io.taskOperation.urn,
		);

		await this.taskOperationTagCollection
			.setFilter('targetUrn', taskOperationUrns)
			.list();
	}

	@computed
	public get taskOperations() {
		const toIds = this.interventionOperationCollection.distinctIds('taskOperation');
		const taskOperations = this.interventionOperationCollection.map(io => io.taskOperation);
		return toIds.map(id => taskOperations.find(to => to.id === id) || new TaskOperationModel());
	}

	@computed
	public get task() {
		return this.interventionOperationCollection.first()?.task || new TaskModel();
	}
}
