import ClientModel                     from 'Models/directory/ClientModel';
import OwnerModel                      from 'Models/intervention/OwnerModel';
import TaskGroupModel                  from 'Models/intervention/TaskGroupModel';
import TaskLevelModel                  from 'Models/intervention/TaskLevelModel';
import TaskStatusModel                 from 'Models/intervention/TaskStatusModel';
import SourceModel                     from 'Models/invoice/SourceModel';
import ContractIterationItemEventModel from 'Models/sales/ContractIterationItemEventModel';
import QuotationItemEventModel         from 'Models/sales/QuotationItemEventModel';
import QuotationRequestModel           from 'Models/sales/QuotationRequestModel';
import doc                             from 'decorators/doc';
import model                           from 'decorators/model';
import { Blamable }                    from 'helpers/traits';
import { Timestampable }               from 'helpers/traits';
import InterventionPrivateApiModel     from 'modelx/models/abstracts/InterventionPrivateApiModel';

const customDoc = doc;

@model.staticLabel('Tâche', false)
@model.urnResource('task')
@doc.path('/tasks/{?id}')
export default class TaskModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'owner.clientUrn': string;
		'owner.enterprisePartitionUrn': string;
		'owner.enterpriseUrn': string;
		'owner.ownerSubPartition': id;
		'owner.ownerSubPartition.partitionUrn': string;
		'owner.ownerSubPartition.subPartitionUrn': string;
		'scheduleEndDate[after]': string;
		'scheduleEndDate[before]': string;
		'scheduleEndDate[strictly_after]': string;
		'scheduleEndDate[strictly_before]': string;
		'scheduleStartDate[after]': string;
		'scheduleStartDate[before]': string;
		'scheduleStartDate[strictly_after]': string;
		'scheduleStartDate[strictly_before]': string;
		'sourceUrn': string;
		'taskGroup': id;
		'taskGroup.sourceUrn': string;
		'taskLevel': id;
		'taskLevel.reference': TaskLevelReference;
		'taskMetas': id;
		'taskMetas.reference': TaskMetaReference;
		'taskMetas.value': id;
		'taskOperations': id;
		'taskOperations.operation': id;
		'taskOperations.operation.operationCategory': id;
		'taskOperations.taskZone': id;
		'taskStatus': id;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'scheduleEndDate': string;
		'scheduleStartDate': string;
		'taskLevel.label': string;
		'taskLevel.level': string;
		'title': string;
		'updatedAt': string;
	}> = {};

	@doc.modelUrn(ClientModel) declare client: ClientModel;
	@doc.string declare description: string;
	@doc.string declare note: string;
	@doc.model(OwnerModel) declare owner: OwnerModel;
	@doc.date declare scheduleEndDate?: Moment;
	@doc.date declare scheduleStartDate?: Moment;
	@doc.model(TaskGroupModel) declare taskGroup: TaskGroupModel;
	@doc.model(TaskLevelModel) declare taskLevel: TaskLevelModel;
	@doc.model(TaskStatusModel) declare taskStatus: TaskStatusModel;
	@doc.string declare title: string;

	@customDoc.modelUrn(SourceModel) declare source: QuotationItemEventModel | ContractIterationItemEventModel | QuotationRequestModel | SourceModel;

	@model.nestedResolvable()
	public get subPartition() {
		return this.owner.ownerSubPartition.subPartition;
	}
}