import ApeCodeModel             from 'Models/directory/ApeCodeModel';
import EnterpriseGroupModel     from 'Models/directory/EnterpriseGroupModel';
import EnterpriseTypeModel      from 'Models/directory/EnterpriseTypeModel';
import NafCodeModel             from 'Models/directory/NafCodeModel';
import SupplierEnterpriseModel  from 'Models/supplier/SupplierEnterpriseModel';
import doc                      from 'decorators/doc';
import model                    from 'decorators/model';
import { Timestampable }        from 'helpers/traits';
import { Blamable }             from 'helpers/traits';
import DirectoryPrivateApiModel from 'modelx/models/abstracts/DirectoryPrivateApiModel';

@model.staticLabel('Entreprise', false)
@model.urnResource('enterprise')
@doc.path('/enterprises/{?id}')
export default class EnterpriseModel extends Blamable(Timestampable(DirectoryPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'commercialName': string;
		'companies.tags[serviceUrn][tagReference]': id;
		'enterpriseGroup': id;
		'enterprisePartitions.clientPartitions.clients.subPartitionUrn': Urn;
		'enterprisePartitions.partitionUrn': Urn;
		'enterpriseType': id;
		'intraCommunityVat': string;
		'name': string;
		'number': number;
		'reference': DirectoryEnterpriseReference;
		'siren': string;
		'tags[$:registry:service:supplier][supplier]': Urn | 'global';
	}> = {};

	public _sorts: ModelSortsExtended<{
		'apeCode.code': string;
		'commercialName': string;
		'id': string;
		'intraCommunityVat': string;
		'nafCode.code': string;
		'name': string;
		'number': string;
		'reference': string;
		'siren': string;
	}> = {};

	@doc.model(ApeCodeModel) declare apeCode: ApeCodeModel;
	@doc.string declare commercialName: string;
	@doc.model(EnterpriseGroupModel) declare enterpriseGroup: EnterpriseGroupModel;
	@doc.model(EnterpriseTypeModel) declare enterpriseType: EnterpriseTypeModel;
	@doc.string declare intraCommunityVat: string;
	@doc.model(NafCodeModel) declare nafCode: NafCodeModel;
	@doc.string declare name: string;
	@doc.number declare number: number;
	@doc.string declare reference: DirectoryEnterpriseReference;
	@doc.string declare siren: string;

	@model.reverseResolvable<SupplierEnterpriseModel>({ multipleRequests: true, urn: '$:supplier:supplier_enterprise', useUrn: true })
	public declare supplierEnterprise: SupplierEnterpriseModel;
}