import ClientModel           from 'Models/directory/ClientModel';
import StaffMemberModel      from 'Models/rh/StaffMemberModel';
import ActivityCategoryModel from 'Models/sales/ActivityCategoryModel';
import ContractStatusModel   from 'Models/sales/ContractStatusModel';
import OwnerModel            from 'Models/sales/OwnerModel';
import doc                   from 'decorators/doc';
import model                 from 'decorators/model';
import { Timestampable }     from 'helpers/traits';
import { Blamable }          from 'helpers/traits';
import { computed }          from 'mobx';
import moment                from 'moment';
import SalesPrivateApiModel  from '../../abstracts/SalesPrivateApiModel';

@model.staticLabel('Contrat')
@model.urnResource('contract')
@doc.path('/contracts/{?id}')
export default class ContractModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'activityCategory': id;
		'contractCategory': id;
		'contractCategory.reference': SalesContractCategoryReference;
		'contractOrigins': id;
		'contractOrigins.origin': id;
		'contractOrigins.origin.reference': SalesOriginReference;
		'contractStatus': id;
		'contractStatus.reference': SalesContractStatusReference;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'exists[contractIterations.reconductions]': boolean;
		'exists[contractIterations.reconductedIterations]': boolean;
		'exists[reconductabilities]': boolean;
		'exists[reconductabilities.recurringMode]': boolean;
		'exists[reconductabilities.extraPeriodDuration]': boolean;
		'exists[reconductabilities.maxExtraPeriod]': boolean;
		'exists[reconductabilities.revisionClause]': boolean;
		'exists[engagementDuration]': boolean;
		'exists[engagementStart]': boolean;
		'exists[engagementEnd]': boolean;
		'owner.clientPartitionUrn': string;
		'owner.clientUrn': string;
		'owner.companyUrn': string;
		'owner.enterpriseUrn': string;
		'owner.ownerSubPartition': id;
		'owner.ownerSubPartition.ownerPartition.partitionUrn': string;
		'owner.ownerSubPartition.subPartitionUrn': string;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'number': string;
		'oldNumber': string;
		'updatedAt': string;
	}> = {};

	@doc.model(ActivityCategoryModel) declare activityCategory: ActivityCategoryModel;
	@doc.modelUrn(ClientModel) declare client: ClientModel;
	@doc.model(ContractStatusModel) declare contractStatus: ContractStatusModel;
	@doc.string declare number: string;
	@doc.string declare oldNumber: string;
	@doc.model(OwnerModel) declare owner: OwnerModel;
	@doc.modelUrn(StaffMemberModel) declare staffMember: StaffMemberModel;
	@doc.date declare engagementDuration: number | null;
	@doc.date declare engagementStart: Moment | null;
	@doc.date declare engagementEnd: Moment | null;

	@computed
	public get fullNumber(): string {
		let fullNumber = this.number;
		if (this.oldNumber) {
			fullNumber = fullNumber + ' (' + this.oldNumber + ')';
		}
		return fullNumber;
	}

	@computed
	public get isRecent() {
		return moment(this.createdAt).diff(moment(), 'days') <= 30;
	}
}
