import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import InterventionPrivateApiModel from 'modelx/models/abstracts/InterventionPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('InterventionNotificationType')
@model.urnResource('intervention_notification_type')
@doc.path('/intervention_notification_types/{?id}')
export default class InterventionNotificationTypeModel extends Timestampable(InterventionPrivateApiModel) {
	public _filters: ModelFiltersExtended<{
		'reference': InterventionInterventionNotificationTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: InterventionInterventionNotificationTypeReference;
}