import ContractModel        from 'Models/sales/ContractModel';
import RecurringModeModel   from 'Models/sales/RecurringModeModel';
import doc                  from 'decorators/doc';
import model                from 'decorators/model';
import SalesPrivateApiModel from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.staticLabel('Paramétrage des reconductions')
@model.urnResource('reconductability')
@doc.path('/reconductabilities/{?id}')
export default class ReconductabilityModel extends SalesPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'contract': id;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@doc.model(ContractModel) declare contract: ContractModel;
	@doc.string declare revisionClause: string;

	@doc.model(RecurringModeModel) declare recurringMode: RecurringModeModel | null;
	@doc.number declare extraPeriodDuration: number | null;
	@doc.number declare maxExtraPeriod: number | null;
	@doc.number declare noticePeriod: number | null;

	/** @deprecated */
	@doc.number declare maxReconductions: number;
	/** @deprecated */
	@doc.number declare nbReconductions: number;
	/** @deprecated */
	@doc.number declare reconductionDuration: number;
}